import "./ins.scss";

import React, { useEffect, useRef, useState } from "react";
import { disableScroll, enableScroll } from "../../util/functions";

import Post from "./templates/post";
import Reals from "./Reals";
import { Waypoint } from "react-waypoint";
import axios from "axios";
import config from "../../util/config";
import cookie from "react-cookies";
import { useData } from "../../util/dataProvider";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function Instagram({ data, mode, time }) {
  const { slideshow } = data;
  let count = new Array(10).fill(0);
  const { testId, testMode, debug } = useParams();
  const { trackingArray, settrackingArray, reset, setUserId } = useData();
  const [started, setStarted] = useState(false);
  const history = useHistory();
  const endSwitch = useRef();
  const [outsidePlaying, setOutsidePlaying] = useState(false);
  const [callbackUrl, setCallbackUrl] = useState(cookie.load("callbackUrl"));
  const [tic, setTic] = useState(cookie.load("testuser_id"));

  const [durationArray, setDurationArray] = useState(
    Array.from({ length: data.posts.length }, (i) => 10000)
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function sumOfFirstNElements(array, n) {
    let sum = 0;
    for (let i = 0; i < n && i < array.length; i++) {
      sum += array[i];
    }
    if (sum == 0) {
    }
    return sum;
  }

  useEffect(() => {
    if (testMode == "slideshow") {
      disableScroll();
    } else {
      enableScroll();
    }
  }, [testMode]);

  function endTrack() {
    let array = [...trackingArray];
    let now = Math.floor(Date.now());
    array.push({
      second: now,
      action: "scroll_bottom",
    });
    settrackingArray([...array], testMode);
  }

  useEffect(() => {
    setUserId(testId);
    let array = trackingArray;
    array.push({
      second: Math.floor(Date.now()),
      action: "start",
    });
    settrackingArray([...array], testMode);
  }, [testId]);

  if (callbackUrl && !started) {
    return (
      <div
        id="tik-tok"
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button onClick={() => setStarted(true)} className="test-end">
          Test starten
        </button>
      </div>
    );
  }

  return (
    <>
      <div id={"instagram"} className={mode == "reel" ? "reel" : ""}>
        <Loader />
        {mode == "start" ? (
          <>
            <div className="header">
              <img src="https://www.instagram.com/static/images/web/mobile_nav_type_logo.png/735145cfe0a4.png" />
              <div className="header-icon-bar">
                <div className="header-icon tv"></div>
                <div className="header-icon send"></div>
              </div>
            </div>
            <div className="stream-bar-container">
              <div className="stream-bar">
                <div className="user-stream">
                  <div
                    className="user-stream-img"
                    style={{
                      backgroundImage: `url(${data.user.bild})`,
                    }}
                  >
                    <div className="plus-icon"></div>
                  </div>
                  <span>{data.user.name}</span>
                </div>
                {data.friends.map((friend, idx) => {
                  return (
                    <div className="user-stream" key={`ins-friend-${idx}`}>
                      <div
                        className="user-stream-img"
                        style={{
                          backgroundImage: `url(${friend.profilbild})`,
                        }}
                      ></div>
                      <span>
                        {friend.name.length > 7
                          ? friend.name.slice(0, 7) + "..."
                          : friend.name}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
            {data &&
              data.posts.map((post, idx) => {
                let length = data.posts.length;
                let link = 0;
                if (post.inhalt.acf_fc_layout === "link") {
                  length = length - 1;
                  link = link + 1;
                }

                return (
                  <Post
                    enableScroll={enableScroll}
                    disableScroll={disableScroll}
                    outsidePlaying={outsidePlaying}
                    key={`ins-post-${idx}`}
                    data={post}
                    user={data.user}
                    setDurationArray={setDurationArray}
                    durationArray={durationArray}
                    slideshow={testMode}
                    index={idx}
                    sumtime={time}
                    endPost={idx == data.posts.length - 1 ? true : false}
                  />
                );
              })}
            <Waypoint
              scrollableAncestor={window}
              topOffset={"50px"}
              bottomOffset={"50px"}
              onEnter={() => endTrack()}
            >
              {callbackUrl ? (
                <button
                  className="test-end"
                  onClick={async function () {
                    const callbackUrlReplaced = callbackUrl
                      .replace("https://", "")
                      ?.replace("http//", "");
                    await reset();
                    window.location.href =
                      "https://" + callbackUrlReplaced + "?i_survey=" + tic;
                  }}
                >
                  Test beenden
                </button>
              ) : (
                <div ref={endSwitch} id="instagram-end">
                  {testMode == "normal" ? (
                    <button
                      className="test-end"
                      onClick={async function () {
                        await reset();
                        await new Promise((r) => setTimeout(r, 300));
                        history.push(
                          `/test/${testId}/slideshow/${
                            debug == "debug" ? "debug" : "normal"
                          }`
                        );
                      }}
                    >
                      Diashow starten
                    </button>
                  ) : (
                    <button
                      className="test-end"
                      onClick={async function () {
                        await reset();
                        await new Promise((r) => setTimeout(r, 300));
                        history.push(
                          `/test/${testId}/normal/${
                            debug == "debug" ? "debug" : "normal"
                          }`
                        );
                      }}
                    >
                      Scroll modus starten
                    </button>
                  )}
                  {testMode !== "normal" && (
                    <button
                      className="test-end"
                      onClick={async function () {
                        await reset();
                        await new Promise((r) => setTimeout(r, 300));
                        history.push(`/`);
                      }}
                    >
                      Test beenden
                    </button>
                  )}
                </div>
              )}
            </Waypoint>
          </>
        ) : (
          <Reals data={data} />
        )}
        {mode == "start" ? (
          <div className="footer">
            <div className="icon-home"></div>
            <div className="icon-search"></div>

            <div className="icon-posting"></div>
            <div className="icon-reels"></div>
            <div className="icon-userinfo"></div>
          </div>
        ) : (
          <div className="footer black">
            <div className="icon-home"></div>
            <div className="icon-search"></div>
            <div className="icon-posting"></div>
            <div className="icon-reels"></div>
            <div className="icon-userinfo"></div>
          </div>
        )}
      </div>
    </>
  );
}

const Loader = () => {
  useEffect(() => {
    const videos = document.getElementsByTagName("video");
    if (videos) {
      for (let i = 1; i < 5; i++) {
        videos[i].load();
      }
    }
  }, []);
  return <div className="loader"></div>;
};
