import "./igstory.scss";
import "swiper/swiper.scss";
import "swiper/components/effect-cube/effect-cube.scss";
import "swiper/components/lazy/lazy.scss";
import "video.js/dist/video-js.css";

import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectCube, Lazy } from "swiper";
import { disableScroll, enableScroll } from "../../util/functions";
import { toFacebook, toInstagram, toTiktok } from "../../util/testTransition";
import { useHistory, useParams } from "react-router-dom";

import Stories from "react-insta-stories";
import VideoPlayer from "./instavideo";
import { Waypoint } from "react-waypoint";
import cookie from "react-cookies";
import { useData } from "../../util/dataProvider";

SwiperCore.use([EffectCube, Lazy]);

export default function InstaStory({ data, time }) {
  const { testId, testMode, debug } = useParams();
  const history = useHistory();
  const { trackingArray, settrackingArray, reset, setUserId } = useData();
  const [callbackUrl, setCallbackUrl] = useState(cookie.load("callbackUrl"));
  const [tic, setTic] = useState(cookie.load("testuser_id"));
  const [end, setEnd] = useState(false);
  const endSwitch = useRef();

  useEffect(() => {
    let activeVideo = document.getElementsByClassName(
      "swiper-slider-active"
    )[0];
    if (activeVideo) {
      let video = activeVideo.getElementsByTagName("video")[0];
      video.onended = function () {
        alert("The audio has ended");
      };
    }
  }, []);

  function slideNextTransition(swiper) {
    let array = trackingArray;
    let now = Math.floor(Date.now());
    array.push({
      second: now,
      item_id: data.posts[swiper.activeIndex - 1].id,
      action: "post_end_focus",
    });

    const items = document.getElementsByClassName("igstory-card");
    if (data.posts[swiper.activeIndex]) {
      if (
        data.posts[swiper.activeIndex - 1].inhalt.acf_fc_layout === "video" ||
        data.posts[swiper.activeIndex - 1].inhalt.acf_fc_layout === "tiktok"
      ) {
        let prevVideo =
          items[swiper.activeIndex - 1].getElementsByTagName("video")[0];
        prevVideo.pause();
      }
      if (
        data.posts[swiper.activeIndex].inhalt.acf_fc_layout === "video" ||
        data.posts[swiper.activeIndex].inhalt.acf_fc_layout === "tiktok"
      ) {
        let startPlayPromise = items[swiper.activeIndex]
          .getElementsByTagName("video")[0]
          .play();

        if (startPlayPromise !== undefined) {
          startPlayPromise
            .then(() => {
              // Start whatever you need to do only after playback
              // has begun.
            })
            .catch((error) => {
              if (error.name === "NotAllowedError") {
                alert(
                  "Browser error occurs, please start test from beginning again"
                );
              } else {
                // Handle a load or playback error
              }
            });
        }
      }
    } else {
      if (
        data.posts[swiper.activeIndex - 1].inhalt.acf_fc_layout === "video" ||
        data.posts[swiper.activeIndex - 1].inhalt.acf_fc_layout === "tiktok"
      ) {
        let prevVideo =
          items[swiper.activeIndex - 1].getElementsByTagName("video")[0];
        prevVideo.pause();
      }
    }

    if (data.posts[swiper.activeIndex]) {
      array.push({
        second: now + 1,
        item_id: data.posts[swiper.activeIndex].id,
        action: "post_start_focus",
      });
    } else {
      array.push({
        second: now + 1,
        action: "scroll_bottom",
      });
    }
    settrackingArray([...array]);
  }

  function slidePrevTransitionend(swiper) {
    let array = trackingArray;
    let now = Math.floor(Date.now());

    const items = document.getElementsByClassName("igstory-card");
    if (data.posts[swiper.activeIndex + 1]) {
      if (
        data.posts[swiper.activeIndex + 1].inhalt.acf_fc_layout === "video" ||
        data.posts[swiper.activeIndex + 1].inhalt.acf_fc_layout === "tiktok"
      ) {
        items[swiper.activeIndex + 1].getElementsByTagName("video")[0].pause();
      }
      if (
        data.posts[swiper.activeIndex].inhalt.acf_fc_layout === "video" ||
        data.posts[swiper.activeIndex].inhalt.acf_fc_layout === "tiktok"
      ) {
        items[swiper.activeIndex].getElementsByTagName("video")[0].play();
      }
    } else {
      if (
        data.posts[swiper.activeIndex].inhalt.acf_fc_layout === "video" ||
        data.posts[swiper.activeIndex].inhalt.acf_fc_layout === "tiktok"
      ) {
        items[swiper.activeIndex].getElementsByTagName("video")[0].play();
      }
    }

    if (data.posts[swiper.activeIndex + 1]) {
      array.push({
        second: now,
        item_id: data.posts[swiper.activeIndex + 1].id,
        action: "post_end_focus",
      });
    } else {
    }
    array.push({
      second: now + 1,
      item_id: data.posts[swiper.activeIndex].id,
      action: "post_start_focus",
    });

    settrackingArray([...array]);
  }

  function mutedControl(idx) {
    const active = document.getElementsByClassName("swiper-slide-active");
    const video = active[0].getElementsByTagName("video")[0];
    if (video) {
      if ((video.muted = true)) {
        video.muted = false;
        let array = trackingArray;
        let now = Math.floor(Date.now());
        array.push({
          second: now,
          item_id: data.posts[idx].id,
          action: "video_not_muted",
        });
        settrackingArray([...array]);
      } else {
        video.muted = true;
      }
    }
  }

  function autoSlide(swiper) {
    let activeVideo = document.getElementsByClassName("swiper-slide-active")[0];
    if (activeVideo) {
      let video = activeVideo.getElementsByTagName("video")[0];
      if (video) {
        video.onended = function () {
          next(swiper);
        };
      } else {
        setTimeout(() => {
          swiper.slideNext();
        }, 5000);
      }
    }
  }

  const next = (swiper) => {
    if ((time / 100) % 5 !== 0) {
      setTimeout(() => {
        swiper.slideNext();
      }, 5000 - ((time / 100) % 5) * 1000);
    } else {
      swiper.slideNext();
    }
  };
  function endTrack() {
    enableScroll();
    setEnd(true);
    let array = [...trackingArray];
    let now = Math.floor(Date.now());
    array.push({
      second: now,
      action: "scroll_bottom",
    });
    settrackingArray([...array]);
  }
  useEffect(() => {
    setUserId(testId);
    let array = trackingArray;
    array.push({
      second: Math.floor(Date.now()),
      action: "start",
    });
    array.push({
      second: Math.floor(Date.now()),
      item_id: data.posts[0].id,
      action: "post_start_focus",
    });

    settrackingArray([...array]);
  }, []);
  return (
    <div className="igstory-container">
      {testMode == "slideshow" && !end && (
        <div className="slideshow-cover"></div>
      )}
      <Swiper
        effect="cube"
        lazy={true}
        onSwiper={(swiper) => {
          autoSlide(swiper);
        }}
        onSlideNextTransitionEnd={(swiper) => {
          slideNextTransition(swiper);
          autoSlide(swiper);
        }}
        onSlidePrevTransitionEnd={(swiper) => {
          slidePrevTransitionend(swiper);
          autoSlide(swiper);
        }}
        onReachEnd={() => {
          endTrack();
        }}
      >
        {data.posts.map((post, idx) => {
          const videoJsOptions = {
            autoplay: idx === 0 ? true : false,
            controls: true,
            muted: true,
            sources: [
              {
                src: post.inhalt.video
                  ? post.inhalt.video
                  : post.inhalt.videourl,
              },
            ],
          };
          const stories = [
            {
              url: post.inhalt.bildurl,
              duration: 5000,
            },
          ];

          return (
            <SwiperSlide>
              {({ isActive }) => (
                <div className="igstory-card">
                  <div
                    className="wrapper"
                    onClick={() => mutedControl(idx)}
                  ></div>
                  <div
                    className="ig-author-bild"
                    style={{
                      backgroundImage: `url(${post.autor.profilbild})`,
                    }}
                  ></div>
                  <div className="ig-author">{post.autor.name}</div>
                  {post.inhalt.acf_fc_layout === "image" ? (
                    <Stories
                      stories={stories}
                      width={432}
                      height={768}
                      autoplay={isActive}
                    />
                  ) : (
                    <VideoPlayer {...videoJsOptions} class="swiper-lazy" />
                  )}
                  <div className="igstory-footer">
                    <div className="ig-message">Nachricht senden</div>
                    <div className="ig-send-icon"></div>
                  </div>
                </div>
              )}
            </SwiperSlide>
          );
        })}
        <SwiperSlide>
          <div
            className="igstory-card"
            style={{ backgroundColor: "rgb(17,17,17)" }}
          >
            <div className="button-container">
              {callbackUrl ? (
                <button
                  className="test-end"
                  onClick={function () {
                    reset();
                    window.location.href =
                      "https://" + callbackUrl + "/?i_survey=" + tic;
                  }}
                >
                  Test beenden
                </button>
              ) : (
                <Waypoint
                  scrollableAncestor={window}
                  topOffset={"50px"}
                  bottomOffset={"50px"}
                >
                  <div ref={endSwitch} id="tiktok-end">
                    {testMode === "normal" ? (
                      <button
                        className="test-end"
                        onClick={async function () {
                          await reset();
                          history.push(
                            `/test/${testId}/slideshow/${
                              debug == "debug" ? "debug" : "normal"
                            }`
                          );
                        }}
                      >
                        Diashow starten
                      </button>
                    ) : (
                      <button
                        className="test-end"
                        onClick={async function () {
                          await reset();
                          history.push(
                            `/test/${testId}/normal/${
                              debug == "debug" ? "debug" : "normal"
                            }`
                          );
                        }}
                      >
                        Scroll modus starten
                      </button>
                    )}
                    {testMode !== "normal" && (
                      <button
                        className="test-end"
                        onClick={async function () {
                          await reset();
                          history.push(`/`);
                        }}
                      >
                        Test beenden
                      </button>
                    )}
                  </div>
                </Waypoint>
              )}
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
