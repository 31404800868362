import "./styles/index.scss";

import { Login, Start, Test } from "./components";
import { Offline, Online } from "react-detect-offline";
import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import { DataProvider } from "./components/util/dataProvider";
import qs from "query-string";

function App() {
  useEffect(() => {}, [localStorage.getItem("september-password")]);

  let test = qs.parse(window.location.search).test;
  let testuser_id = qs.parse(window.location.search).i_survey;
  let medium = qs.parse(window.location.search).medium;
  let redirect_url = qs.parse(window.location.search).callback_url;

  return (
    <div className="App">
      <Switch>
        <Route path="/test/:testId/:testMode/:debug">
          <DataProvider>
            <Test />
          </DataProvider>
        </Route>
        <Route path="/test/:testId" component={Test} />

        {localStorage.getItem("september-password") ? (
          <Route path="/" component={Start} />
        ) : test && testuser_id ? (
          <Route path="/" component={Start} />
        ) : (
          <Route path="/" component={Login} />
        )}
      </Switch>
    </div>
  );
}

export default App;
