import React, { useEffect, useRef, useState } from "react";

import ReactPlayer from "react-player";
import { Waypoint } from "react-waypoint";
import { useData } from "../../../util/dataProvider";

export default function Video({
  data,
  id,
  durationArray,
  time,
  setDurationArray,
  executeScroll,
  slideshow,
  outsidePlaying,
}) {
  let [shouldPlay, updatePlayState] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [inViewport, setInViewport] = useState(false);
  const { trackingArray, settrackingArray } = useData();
  const videoRef = useRef();

  useEffect(() => {
    if (slideshow == "slideshow") {
      const video = document.getElementById(`video-${id}`);
      if (video) {
        video.addEventListener("ended", executeScroll);
        return () => {
          video.removeEventListener("ended", executeScroll);
        };
      }
    }
  }, [slideshow, time]);

  const handleEnterViewport = () => {
    if (true) {
      console.log("entering");
      const video = videoRef.current;
      if (video) {
        video.play();
        updatePlayState(true);
        setInViewport(true);
      }
    }
  };
  let handleExitViewport = function () {
    if (true) {
      const video = videoRef.current;
      if (video) {
        video.pause();
        updatePlayState(false);
      }
    }
  };

  useEffect(() => {
    let array = [...trackingArray];
    let now = Math.floor(Date.now());
    array.push({
      second: now,
      item_id: id,
      action: isMuted ? "video_muted" : "video_not_muted",
    });
    settrackingArray([...array]);
  }, [isMuted]);

  useEffect(() => {
    let array = trackingArray;
    let now = Math.floor(Date.now());
    if (
      shouldPlay ||
      trackingArray?.findIndex((ele) => ele.item_id == id) >= 0
    ) {
      array.push({
        second: now,
        item_id: id,
        action: shouldPlay ? "video_start" : "video_end",
      });
    }
    settrackingArray([...array]);
  }, [shouldPlay]);

  return (
    <div className="post-template video">
      <Waypoint
        scrollableAncestor={window}
        topOffset="200px"
        bottomOffset="200px"
        onEnter={handleEnterViewport}
        onLeave={handleExitViewport}
      >
        <div>
          <video
            playsInline
            src={data.alternative_url ? data.alternative_url : data.videourl}
            type="video/mp4"
            ref={videoRef}
            width={"100%"}
            height={"auto"}
            id={`video-${id}`}
          ></video>
        </div>
      </Waypoint>
    </div>
  );
}
